<template>
	<div>
		<a-layout>
			<div class="content-header">
				<a-affix :offset-top="0" style="width: 100%">
					<a-row type="flex" justify="space-between">
						<a-col span="12">
							<common-page-btn @add="$refs.addForm._show()" @category="$refs.category._show()"></common-page-btn>
						</a-col>
						<a-col span="12">
							<a-row type="flex" align="middle" justify="end" :gutter="3">
                <a-col span="12">
									<a-select style="width: 100%" placeholder="请选择分类" @change="doRefresh()" allowClear v-model="param.categoryId">
										<a-select-option v-for="category in categoryList" :key="category.categoryId" :value="category.categoryId">{{ category.name }}</a-select-option>
									</a-select>
								</a-col>
								<a-col span="12">
									<a-input v-model="param.keyword" allowClear search @pressEnter="getList()" @change="change" @on-clear="getList()" placeholder="关键词查询"></a-input>
								</a-col>
								
							</a-row>
						</a-col>
					</a-row>
				</a-affix>
			</div>
		</a-layout>

		<a-table :columns="columns" :defaultExpandAllRows="true" :scroll="{ x: '100%' }" :pagination="false" :indentSize="25" :style="{ background: '#FFF' }" size="small" row-key="questionId" border :loading="loading" :data-source="data.records">
			<template slot-scope="row" slot="questionTitle">
				<div style="display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; overflow: hidden" v-html="row.questionTitle"></div>
			</template>
			<!--操作-->
			<template slot-scope="row" slot="action">
				<a-button-group shape="circle" size="small">
					<a-tooltip title="修改数据"><a-button v-has="'integral:questionbank:add'" type="link" icon="edit" @click="$refs.addForm._show(row)"></a-button></a-tooltip>
					<a-tooltip title="删除数据"><a-button v-has="'integral:questionbank:del'" type="link" icon="delete" @click="del(row.questionId)"></a-button></a-tooltip>
				</a-button-group>
			</template>
		</a-table>
		<a-row>
			<a-col span="12" offset="6" style="text-align: center; padding: 20px 0">
				<a-pagination :defaultPageSize="param.limit" :total="data.total" @change="getNext" @showSizeChange="setLimit" :current="data.current" />
			</a-col>
		</a-row>
		<question-form ref="addForm" @success="doRefresh"></question-form>
		<quest-category ref="category" @success="doRefresh"></quest-category>
	</div>
</template>

<script>
import apiUtil from '@/common/apiUtil';
import utils from '@/common/utils';
import questionForm from './components/questionForm.vue';
import questCategory from './components/questCategory.vue';

export default {
	components: { questionForm, questCategory },
	data() {
		return {
			data: {},
			loading: false,
			organList: apiUtil.getOrganList(),
			pageSize: 10,
			categoryList: [],
			total: '',
			param: {
				current: 1,
				limit: 20,
				keyword: '',
				categoryId: undefined
			},
			columns: [
				{ title: '题目', scopedSlots: { customRender: 'questionTitle' }, width: 100 },
				{ title: '可获积分', dataIndex: 'score', width: 160, resizable: true, align: 'center' },
				{ title: '归属分类', dataIndex: 'categoryName', width: 160, resizable: true, align: 'center' },
				{ title: '答案', dataIndex: 'correctAnswer', width: 160, resizable: true, align: 'center' },
				{ title: '操作', scopedSlots: { customRender: 'action' }, align: 'center', width: 100 }
			]
		};
	},
	mounted() {
		this.getList();
		this.getCategoryList();
	},
	methods: {
		...apiUtil,

		// 获取数据
		getList() {
			this.loading = true;
			if (this.param.categoryId === undefined) {
				this.param.categoryId = '';
			}
			this.http
				.get('/platform/QuestionBank/questionList', this.param)
				.then(ret => {
					this.data = ret.data;
					this.total = data.total;
					this.param.current = data.current;
					this.loading = false;
					if (this.param.categoryId === '') {
						this.param.categoryId = undefined;
					}
				})
				.catch(err => {
					this.loading = false;
          if (this.param.categoryId === '') {
						this.param.categoryId = undefined;
					}
				})
		},
		change(e) {
			if (e.target.value == '') {
				this.getList();
			}
		},
		// 删除
		del(id) {
			this.utils.confirm('删除后无法恢复，是否确认?').then(() => {
				utils.showSpin();
				this.http.get('/platform/QuestionBank/delete', { questionId: id }).then(ret => {
					console.log(ret);
					if (ret.code == 200) {
						this.utils.success('操作成功！').then(() => {
							this.getList();
							console.log(1111);
						});
					}
				});
			});
		},
		// 筛选条件变化重新获取数据
		doRefresh() {
			this.param.current = 1;
			this.getList();
		},

		// 筛选单位
		filterOrgan(e) {
			this.param.organId = e;
		},
		//设置每页的显示条数
		setLimit(current, limit) {
			this.param.page = 1;
			this.param.limit = limit;
			this.getList();
		},
		//页码切换
		getNext(page) {
			this.param.page = page;
			this.getList();
		},
		// 获取分类数据
		getCategoryList() {
			this.loading = true;
			this.http
				.get('/platform/QuestionBank/categoryList')
				.then(ret => {
					if (ret.code == 200) {
						this.loading = false;
						this.categoryList = ret.data;
					}
				})
				.catch(err => {
					this.loading = false;
					console.log(err);
				});
		}
	}
};
</script>

<style></style>
